import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {};

const Contacts = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Contacts</title>
			</Helmet>
			<div className="mx-auto centered container text-2xl font-regular">
				<h1 className="mb-5 text-6xl font-title font-bold">Contacts</h1>
				<p>Full name of the organization:</p>
				<p className="font-bold mb-4">VODORIA LIMITED</p>
				<p className="mb-4"><b>Registration number:</b> HE 342815</p>
				<p className="mb-4"><b>VAT-number:</b> 10342815D</p>
				<p className="mb-4"><b>Director:</b> LIUBOV MARTIN</p>
				<p className="mb-4"><b>Legal address:</b> Diagorou,4 KERMIA BUILDING, 5th floor, Office 504, 1097, Nicosia, Cyprus</p>
				<p className="mb-4"><b>Tel.:</b> +357 22 108719</p>
				<p className="mb-4"><b>E-mail:</b> general@vodoria.net</p>
				<p className="mb-4"><b>Sales department:</b> sales@theta-wave.io</p>
			</div>
		</>
	);
};

export default Contacts;
